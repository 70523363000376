<template>
  <div>
    <el-dialog
      :title="title + '房态联动配置信息'"
      :visible.sync="showing"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <div>
          <!-- 一个表单 -->
          <el-form
            ref="formInline"
            :model="formInline"
            @submit.native.prevent
            :rules="rules"
          >
            <div class="banben">
              <div class="bandanItem" style="width: 45%">
                <el-form-item label="酒店" prop="hotelId">
                  <el-select
                    class="text"
                    style="width: 100%"
                    v-model="formInline.hotelId"
                    :disabled="hotel"
                    placeholder="请选择酒店"
                  >
                    <el-option
                      v-for="item in hotelList"
                      :key="item.id"
                      :label="item.hotelName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="bandanItem" style="width: 45%">
                <el-form-item label="设备类型" prop="deviceType">
                  <el-select
                    class="text"
                    style="width: 100%"
                    v-model="formInline.deviceType"
                    placeholder="请选择设备类型"
                    @change="changeDeviceType"
                  >
                    <el-option
                      v-for="item in deviceTypeList"
                      :key="item.code"
                      :label="item.typeName"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="banben">
              <div class="bandanItem" style="width: 45%">
                <el-form-item label="设备别名" prop="deviceNameId">
                  <el-select
                    class="text"
                    style="width: 100%"
                    v-model="formInline.deviceNameId"
                    placeholder="请选择设备别名"
                  >
                    <el-option
                      v-for="item in deviceNameList"
                      :key="item.id"
                      :label="item.deviceOtherName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="bandanItem" style="width: 45%">
                <el-form-item label="房态" prop="roomStatus">
                  <el-select
                    class="text"
                    style="width: 100%"
                    v-model="formInline.roomStatus"
                    placeholder="请选择房态"
                  >
                    <el-option
                      v-for="item in roomStatusList"
                      :key="item.statusDesc"
                      :label="item.roomStatus"
                      :value="item.statusDesc"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div
              class="banben"
              style="border-bottom: 1px solid #d8d8d8"
              v-if="title == '编辑'"
            >
              <div class="bandanItem" style="width: 45%">
                <el-form-item label="排序" prop="dispOrder" style="width: 100%">
                  <el-input
                    v-model="formInline.dispOrder"
                    placeholder="请输入排序"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
          <!-- <el-form :model="csformInline"> </el-form> -->
          <div v-for="(item, index) in csformInline" :key="index">
            <div class="shuzhi" v-if="item.values.length == 1">
              <div class="shuzhi1">{{ item.paramName }}:</div>
              <div class="shuzhi2">
                <el-input
                  v-model="item.valueCode"
                  placeholder="设定值"
                ></el-input>
              </div>
            </div>
            <div class="shuzhi" v-else>
              <div class="shuzhi1">{{ item.paramName }}:</div>
              <div class="shuzhi2">
                <el-select
                  class="text"
                  style="width: 100%"
                  v-model="item.valueCode"
                  placeholder="请选择房间状态"
                >
                  <el-option
                    v-for="item1 in item.values"
                    :key="item1.valueCode"
                    :label="item1.valueDesc"
                    :value="item1.valueCode"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="createStorage('formInline')"
              >确定</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { createInsertUser, updateInsertUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";

export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      hotel: true,
      index: null,
      //表单
      formInline: {},
      csformInline: {},
      hotelList: [],
      roomStatusList: [],
      deviceTypeList: [],
      deviceNameList: [],
      roomStatusList: [],
      parms: {},
      //表单验证
      rules: {
        deviceType: [
          {
            required: true,
            message: "请选择设备类型",
            trigger: "blur",
          },
        ],
        deviceNameId: [
          {
            required: true,
            message: "请选择设备别名",
            trigger: "blur",
          },
        ],
        roomStatus: [
          {
            required: true,
            message: "请选择房态",
            trigger: "blur",
          },
        ],
        dispOrder: [
          {
            required: true,
            message: "请输入排序",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
    },
    show(item, val, index) {
      this.formInline = {
        arStatus: 0,
        arModule: "Cold",
        arSpeed: "Auto",
        arTemperature: "26",
      };
      let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
      this.formInline.hotelId = Number(hotelInfor.hotelId);
      this.getHotelList();
      this.getDeviceTypeList();
      this.getdeviceList();
      this.getRoomStatusList();
      this.csformInline = [];
      this.title = item;
      this.showing = true;
      this.bedisabled = false;
    },
    getdeviceList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotelDeviceDefinition", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.deviceNameList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getRoomStatusList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysRoomStatus", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.roomStatusList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getHotelList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        offset: 1,
        num: 10000,
      };
      postRequest("selectAllHotel", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.hotelList = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getDeviceTypeList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysDeviceType", {}).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.deviceTypeList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    createStorage(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          if (this.title == "创建") {
            this.parms.roomStatus = this.formInline.roomStatus;
            this.parms.dispOrder = this.formInline.dispOrder;
            this.parms.deviceNameId = this.formInline.deviceNameId;
            this.csformInline.forEach((item) => {
              item.values.forEach((item2) => {
                if (item.valueCode == item2.valueCode) {
                  item.valueDesc = item2.valueDesc;
                }
              });
            });
            this.parms.paramList = this.csformInline;
            // console.log(this.parms);
            // return;
            postRequest("/insertRoomStatusControl", this.parms).then((res) => {
              if (res.status == 200) {
                this.$message({
                  duration: 5000,
                  message: "创建成功!",
                  type: "success",
                });
                this.hide();
                this.$refs.formInline.resetFields(); //清空表单
                this.$parent.getFounderList();
              } else {
                this.$message({
                  duration: 5000,
                  message: res.message,
                  type: "warning",
                });
              }
            });
          } else {
            this.updateStation(); //调用编辑函数
          }
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
    // 编辑
    updateStation() {
      console.log(this.formInline);
      // return
      postRequest("/updateHotelAcSummer", this.formInline).then((res) => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "编辑成功!",
            type: "success",
          });
          this.hide();
          this.$refs.formInline.resetFields(); //清空表单
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },

    changeDeviceType(val) {
      console.log(121, val);
      this.deviceTypeList.forEach((item) => {
        if (item.code == val) {
          console.log(item);
          this.parms.deviceType = item.code;
          this.parms.deviceTypeCode = item.typeCode;
          this.parms.deviceTypeName = item.typeName;
        }
      });
      let parms = {
        deviceType: val,
      };
      postRequest("/getSysDeviceParams", parms).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.csformInline = res.data;
        }
      });
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.el-button {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
>>> .el-radio-group {
  display: flex;
}
.shuzhi {
  display: flex;
  margin: 20px 0;
  width: 80%;
}
.shuzhi1 {
  width: 20%;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.shuzhi2 {
  width: 80%;
}
</style>